/* .page-compare-product-prices {
  color:red;
} */

* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
/* article{display:flex;transform:scale(1);transform-origin: top left;flex-wrap:no-wrap;} */

hr {
  margin: 0 10px;
  height: 0;
  border-width: 0;
  border-bottom: 1px solid #ddd;
}

.page-compare-product-prices {
  flex: 0 0 350px;
  background-color: #fff;
  width: 350px;
  margin: 10px 25px;
  margin-right: 0;
  height: 600px;
  box-shadow: 0 0 3px #ccc;
  overflow: hidden;
}
.page-compare-product-prices img {
  width: 100%;
  padding: 10px 0;
}
h1 {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
  font-weight: normal;
  font-size: 23px;
  line-height: 50px;
  box-shadow: 0 0 5px hsl(241.9, 47.7%, 61%);
}
i {
  display: inline-block;
  width: 45px;
  text-align: center;
}

.pd_product-head {
  position: relative;
  top: 0;
  left: 0;
  line-height: 50px;
  text-indent: 70px;
  font-weight: bold;
  margin: 5px 0;
}
.pd_product-head img {
  position: absolute;
  top: 0;
  left: 10px;
  width: auto !important;
  height: 40px;
  padding: 5px !important;
}
.pd_list li {
  margin: 10px;
  box-shadow: 0 0 3px #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  padding: 10px;
  padding-left: 90px;
  line-height: 1.3;
}
.pd_list li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px !important;
  padding: 10px !important;
}
.pd_list li .desc > * {
  vertical-align: middle;
}
.pd_list li .price {
  display: inline-block;
  font-size: 1.3em;
  color: #f44;
  font-weight: bold;
  padding-right: 5px;
}
.pd_list li .sitename {
  font-size: 0.7em;
  color: #999;
}
.pd_list li .tag-r {
  background-color: #d70;
  color: #fff;
  border-radius: 5px;
  font-size: 0.7em;
  padding: 2px 5px;
  margin: 5px 0;
  display: inline-block;
}
.pd_list li .tag-b {
  background-color: #09d;
  color: #fff;
  border-radius: 5px;
  font-size: 0.7em;
  padding: 2px 5px;
  margin: 5px 0;
  display: inline-block;
}
