* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
.container {
  background-color: #fff;
}
.container img {
  width: 100%;
  padding: 10px 0;
}
h1 {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
  font-weight: normal;
  font-size: 23px;
  line-height: 50px;
  box-shadow: 0 0 5px hsl(241.9, 47.7%, 61%);
}
i {
  display: inline-block;
  width: 45px;
  text-align: center;
}
article {
  display: flex;
  transform: scale(1);
  transform-origin: top left;
  flex-wrap: no-wrap;
}
hr {
  margin: 0 10px;
  height: 0;
  border-width: 0;
  border-bottom: 1px solid #ddd;
}
