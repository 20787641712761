.calendar {
  width: calc(100% - 10px);
  text-align: center;
  border-collapse: collapse;
  margin: 5px;
}
.calendar thead {
  font-weight: bold;
}
.calendar thead tr > th:first-child {
  color: #e00;
}
.calendar thead tr > th:last-child {
  color: #00f;
}
.calendar td,
.calendar th {
  height: 46px;
}
.calendar td {
  font-size: 18px;
}
.calendar .disabled {
  color: #bbb;
}
.calendar div {
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  border: 0px solid #0084d1;
  margin: 2px 0;
}

.calendar div:not(.date-is-this-month) {
  color: #bbb;
}

.calendar .date-in-range {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
}
.calendar .date-is-start {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.calendar .date-is-end {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
