h1 {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
  font-weight: normal;
  font-size: 23px;
  line-height: 50px;
  box-shadow: 0 0 5px hsl(241.9, 47.7%, 61%);
}
i {
  display: inline-block;
  width: 45px;
  text-align: center;
}

.region-select {
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.region-select li {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  line-height: 50px;
}
.region-select li i {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  line-height: inherit;
}
.page-countryselect .region-select li i:before {
  line-height: inherit;
  font-size: 20px;
  color: #bbb;
}
.page-countryselect .region-select li.sel {
  background-color: hsl(241.9, 47.7%, 61%);
  box-shadow: 0 0 2px hsl(241.9, 47.7%, 61%);
  color: #fff;
}
.region-select li.sel i:before {
  color: #fff;
}

/* .geochart {
  position: absolute;
  top: 0;
  left: 0;
} */

.bclist {
  display: flex;
  flex-direction: row;
}

.bclist-item {
}

.bclist-back {
}

.selected {
  background-color: rgb(218, 217, 241);
}
