.page-product-deatil img {
  width: 100%;
}
.page-product-deatil .description {
  padding: 20px 20px;
  background-color: #f0f0f0;
}
.page-product-deatil .description .description-title {
  font-size: 15px;
  padding: 10px 0;
}
.page-product-deatil .description .description-article {
  font-size: 12px;
}
.page-product-deatil .deatail-list {
  padding: 20px;
}
.page-product-deatil .deatail-list .name {
  font-size: 15px;
  line-height: 45px;
}
.page-product-deatil .deatail-list .price {
  font-size: 20px;
  line-height: 45px;
}
.page-product-deatil .transition {
  border: 1px #bbb;
  padding: 20px;
  position: relative;
}
.page-product-deatil .transition-button {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
  padding: 20px;
  border-radius: 3px;
  cursor: pointer;
}
.page-product-deatil .transition-button p {
  position: absolute;
  top: 35px;
  left: 100px;
}
