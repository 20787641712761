* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
article {
  display: flex;
  transform: scale(1);
  transform-origin: top left;
  flex-wrap: no-wrap;
}
.container {
  background-color: #fff;
}
.container img {
  width: 100%;
  padding: 10px 0;
}
h1 {
  background-color: hsl(241.9, 47.7%, 61%);
  color: #fff;
  font-weight: normal;
  font-size: 23px;
  line-height: 50px;
  box-shadow: 0 0 5px hsl(241.9, 47.7%, 61%);
}
i {
  display: inline-block;
  width: 45px;
  text-align: center;
}
.ev-dtpage > img {
  display: block;
  padding-bottom: 0 !important;
}
.ev-dtpage .evdesc {
  display: flex;
  padding: 10px 0;
}
.ev-dtpage .datetime {
  text-align: center;
  color: #333;
  font-weight: bold;
  line-height: 20px;
  padding: 0 10px;
  padding-right: 15px;
}
.ev-dtpage .datetime .date {
  line-height: 25px;
  font-size: 18px;
}
.ev-dtpage .datetime .time {
  line-height: 15px;
  font-size: 12px;
}
.ev-dtpage .evdetail .evtitle {
  color: #222;
}
hr {
  margin: 0 10px;
  height: 0;
  border-width: 0;
  border-bottom: 1px solid #ddd;
}
.ev_desctitle,
.ev_linkstitle {
  margin: 10px 10px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #222;
}
.ev_desctext,
.ev_linksbody {
  margin: 10px 10px;
  margin-top: 5px;
  font-size: 14px;
}
.ev_linksul {
  list-style-type: none;
  font-size: 16px;
  color: #06f;
  text-decoration: underline;
}
.ev_linksul i {
  width: auto;
  padding: 0 5px;
  color: #444;
}
.ev-dtpage .action_btns {
  list-style-type: none;
  display: flex;
  margin: 10px;
}
.ev-dtpage .action_btns li {
  flex-grow: 1;
  border: 1px solid #ccc;
  margin: 10px 5px;
  line-height: 38px;
  border-radius: 19px;
  text-align: center;
  color: #444;
}
.ev-dtpage .action_btns li > i {
  width: auto;
  padding-right: 5px;
}

.big_category {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  line-height: 50px;
  text-indent: 70px;
  font-weight: bold;
  margin: 5px 0;
}
.big_category img {
  position: absolute;
  top: 0;
  left: 10px;
  width: auto !important;
  height: 40px;
  padding: 5px !important;
}
.big_category i {
  width: 88%;
  position: absolute;
  top: 20px;
}
.small_category {
  background-color: #f0f0f0;
  padding: 5px 0;
}
.small_category li .name_small {
  line-height: 40px;
  text-indent: 90px;
  margin: 5px 0;
  font-size: 14px;
}
