* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
article {
  display: flex;
  transform: scale(1);
  transform-origin: top left;
  flex-wrap: no-wrap;
}
/* .container {
  background-color: #fff;
} */
.container img {
  width: 100%;
  height: 100%;
}

.pagetop-mainimage {
}

.app-start-button {
  width: 100px;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  padding: 0.5em 3em;
  color: #fff;
  background: #003f8e; /*色*/
  border: solid 1px #043c78; /*線色*/
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  margin: auto;
}

.app-feature-title {
  font-size: 16px;
  text-align: center;
  padding: 20px;
}

.app-feature-box {
  width: 100%;
}
.app-description-box {
  display: flex;
}
.app-description-left {
  padding: 20px;
  height: 100px;
}
.app-description-right {
  padding: 20px;
  height: 100px;
}
.app-description-title {
  font-size: 12px;
  text-align: center;
  width: 50%;
}
.app-description-image {
  font-size: 12px;
  text-align: center;
  width: 50%;
}
