.page-date-select .calendar-ym-box {
  padding-top: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 2;
  text-align: center;
  display: flex;
  margin: 0 auto;
}
.page-date-select .calendar-ym-box .calendar-ym-area {
  color: #505050;
  width: calc(100% / 3);
  text-align: center;
}
